import gql from 'graphql-tag'

export const allBrandsAndTagsQuery = gql`
  query allBrandsAndTags {
    allBrands: brands(first: 1000) {
      data {
        id
        name
      }
    }
    allTags: tags(first: 1000) {
      data {
        id
        name
      }
    }
  }
`

export const allBrands = gql`
  query allBrands {
    allBrands: brands(first: 1000) {
      data {
        id
        name
      }
    }
  }
`

export const allTags = gql`
  query allTags {
    allTags: tags(first: 1000) {
      data {
        id
        name
      }
    }
  }
`

export const searchQuery = (text, brand, tags, page = 1) => {
  let brandQuery = ''
  let tagsQuery = ''

  // saturate additional queries if they exist
  if (tags.length > 0) {
    const flatTags = tags.map((tag) => tag.name)
    tagsQuery = `hasAllTags: ${JSON.stringify(flatTags)},`
  }
  if (brand) {
    brandQuery = `hasBrand: {column: NAME, operator: EQ, value: "${brand.name}" },`
  }

  // build query
  let query = gql`query images {
      originals(
          where: {
          OR: [
              {column: TITLE, operator: LIKE, value: "%${text}%"},
              {column: COMMENT, operator: LIKE, value: "%${text}%"},
              {column: ALT_TEXT, operator: LIKE, value: "%${text}%"}
          ]
      },
      ${tagsQuery}
      ${brandQuery}
      page: ${page},
      first: 24    
      ) {
          paginatorInfo {
              count
              total
              hasMorePages
          }
          data {
              id
              title
              comment
              altText
              meta
              created_at
              tags {
                  name
              }
              url
              fragments {
                  id
                  name
                  url
                  meta
                  file_size
              }
              brand {
                  name
                  id
              }
          }
      }
  }`
  return query
}

export const tagsByBrand = (brand) => {
  return gql`query tagsByBrand {
      brands(name: "${brand}") {
          data {
              tags {
                  id
                  name
              }
          }
      }
  }`
}

export const imageEditQuery = (id) => {
  return gql`
      query image {
          originals(id: "${id}") {
              data {
                  id
                  title
                  comment
                  altText
                  meta
                  tags {
                      name
                      id
                  }
                  url
                  fragments {
                      id
                      name
                      url
                      meta
                      file_size
                  }
                  brand {
                      name
                      id
                  }
              }
          }
      }
  `
}
