export default {
  methods: {
    errorNotification(
      title = 'ERROR!',
      e = { error: 'no error data unavailable, try again' }
    ) {
      this.$notify({
        group: 'updates',
        title: title,
        text: JSON.stringify(e),
        type: 'error'
      })
    }
  }
}
