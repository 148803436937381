import { getField, updateField } from 'vuex-map-fields'

import { allBrandsAndTagsQuery } from '../graphql/queries'
import { apolloClient } from '../graphql/apollo'
import createPersistedState from 'vuex-persistedstate'

// modules
import search from '@/store/modules/search'
import edit from '@/store/modules/edit'
import add from '@/store/modules/add'
import brands from '@/store/modules/brands'
import tags from '@/store/modules/tags'

export default function createStoreConfig() {
  return {
    plugins: [
      createPersistedState({
        paths: ['brands.all', 'tags.all', 'search.lastQuery']
      })
    ],
    modules: {
      search,
      edit,
      add,
      brands,
      tags
    },
    state: {
      token: '',
      isLoading: false,
      loadingMessage: 'Loading...'
    },
    getters: {
      getField
    },
    // take action via store.dispatch('fetchBrandsAndTags')
    actions: {
      async fetchBrandsAndTags({ commit }) {
        const { data } = await apolloClient.query({
          query: allBrandsAndTagsQuery
        })
        commit('brands/setBrands', data.allBrands.data)
        commit('tags/setTags', data.allTags.data)
      }
    },

    // mutate via store.commit('setSearchText')
    // mutate via store.commit('setLoading', {stateToSet: 'global', status: true})
    // mutate via store.commit('setField', {module: {name: 'search'}, field: {name: 'page', value: 2}})
    // mutate via store.commit('setField', {module: {name: 'search'}, subfield: {name: 'results'}, field: {name: 'data', value: []}})
    mutations: {
      updateField,
      setLoading(state, data) {
        if (data.stateToSet === 'global') {
          state.isLoading = data.status
          if (data.message) {
            state.loadingMessage = data.message
          } else {
            state.loadingMessage = 'Loading...'
          }
        } else {
          state[data.stateToSet].isLoading = data.status
        }
      },
      clearData(state, stateToClear) {
        state[stateToClear].title = ''
        state[stateToClear].altText = ''
        state[stateToClear].comment = ''
        state[stateToClear].tags = []
        state[stateToClear].brand = ''
        state[stateToClear].type = ''
        state[stateToClear].preview = ''
      },
      setToken(state, token) {
        state.token = token
      },
      setField(state, fieldData) {
        if (fieldData.module) {
          if (fieldData.subfield) {
            state[fieldData.module.name][fieldData.field.name][
              fieldData.subfield.name
            ] = fieldData.subfield.value
            return
          }
          state[fieldData.module.name][fieldData.field.name] =
            fieldData.field.value
          return
        }
        state[fieldData.field.name] = fieldData.field.value
      }
    }
  }
}
