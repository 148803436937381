/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// core deps
import Vue from 'vue'
import vSelect from 'vue-select'
import PortalVue from 'portal-vue'
import VueContentPlaceholders from 'vue-content-placeholders'
import Vuelidate from 'vuelidate'
import Notifications from 'vue-notification'
import VueLazyLoad from 'vue-lazyload'
import VueTailwind from 'vue-tailwind'
import VueMasonry from 'vue-masonry-css'
import infiniteScroll from 'vue-infinite-scroll'
import axios from 'axios'
import Theme from '@/vue-tailwind-theme/default.js'

// mixins
import NotificationMixin from '@/mixins/notifications'
Vue.mixin(NotificationMixin)

import QueriesMixin from '@/mixins/queries'
Vue.mixin(QueriesMixin)

// vue router
import VueRouter from 'vue-router'
import router from '@/router'

// main component to mount router
import App from '@/layout/MainLayout.vue'

// apollo graphql
import { apolloProvider } from '@/graphql/apollo'

// auth0
import { Auth0Plugin } from '@/auth'

// axios requests

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
// bind axios to this.$axios for usage in components
Vue.prototype.$axios = axios

// vuex state store
import store from '@/store'

// globally registered components
import '@/components/_globals'

// styles
import 'vue-select/dist/vue-select.css'

// Editor Style Code
import 'tui-image-editor/dist/tui-image-editor.css'

const clientId = window.auth0.id
const domain = window.auth0.domain
const audience = window.auth0.audience

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.use(VueRouter)
Vue.use(PortalVue)
Vue.use(VueContentPlaceholders)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VueLazyLoad)
Vue.use(VueTailwind, {
  theme: {
    Theme
  }
})
Vue.use(VueMasonry)
Vue.use(infiniteScroll)

Vue.component('v-select', vSelect)

new Vue({
  router,
  store,
  apolloProvider,
  el: '#app',
  components: {
    App
  },
  render: (h) => h(App)
})
