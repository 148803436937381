import { allBrandsAndTagsQuery } from '@/graphql/queries'
export default {
  methods: {
    fetchBrandsAndTags() {
      this.$apollo
        .query({
          query: allBrandsAndTagsQuery
        })
        .then(({ data }) => {
          this.$store.commit('brands/setBrands', data.allBrands.data)
          this.$store.commit('tags/setTags', data.allTags.data)
        })
        .catch((e) => {
          this.errorNotification(
            'Error getting brands and tags. Refreshing token... please wait.',
            e
          )
          setTimeout(() => {
            location.reload()
          }, 1500)
        })
    }
  }
}
