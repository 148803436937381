import { getField, updateField } from 'vuex-map-fields'

import { apolloClient } from '@/graphql/apollo'
import { imageEditQuery } from '@/graphql/queries'
import Vue from 'vue'
import router from '@/router'

const state = {
  activeTab: 'original',
  title: '',
  altText: '',
  comment: '',
  tags: [],
  brand: '',
  type: '',
  preview: null,
  isLoading: null,
  meta: null,
  url: null,
  fragments: [],
  addFragment: {
    ratio: null,
    originalDims: { width: 0, height: 0 },
    scalingDims: { width: 0, height: 0 },
    isModalOpen: false,
    name: ''
  }
}

// getters
const getters = {
  getField
}

// actions
// this.$store.dispatch('edit/actionName', payload)
const actions = {
  async fetchImage({ commit, rootState }, id) {
    apolloClient
      .query({
        query: imageEditQuery(id),
        fetchPolicy: 'network-only'
      })
      .then(
        ({
          data: {
            originals: { data }
          }
        }) => {
          if (data.length < 1) {
            Vue.notify({
              group: 'updates',
              title: 'No Image Found',
              text: `Please try a different image. <a href="${rootState.search.lastQuery}" class="bg-yellow-300 text-black p-2 rounded mb-3">< Back to search</a>`,
              type: 'error',
              duration: -1,
              closeOnClick: false
            })
          } else {
            commit(
              'setLoading',
              { stateToSet: 'edit', status: false },
              { root: true }
            )
            commit('setEditData', data[0])
          }
        }
      )
      .catch((e) => {
        console.error(e)
        Vue.notify({
          group: 'updates',
          title: 'Error getting image info.',
          text: JSON.stringify(e),
          type: 'error'
        })
      })
  }
}

// mutations
// this.$store.commit('edit/mutationName', payload)
const mutations = {
  updateField,
  setEditData(state, data) {
    if (data) {
      const {
        id,
        title,
        comment,
        url,
        altText,
        brand,
        tags,
        fragments,
        meta
      } = data
      state.id = id
      state.title = title
      state.comment = comment
      state.altText = altText
      state.brand = { name: brand.name, id: brand.id }
      state.preview = url.replace('s3:9000', 'localhost:9057')
      state.tags = tags
      state.meta = meta
      state.url = url
      state.fragments = fragments
    }
  },
  toggleScaling(state) {
    state.addFragment.isScaling = !state.addFragment.isScaling
  },
  setDims(state, dims) {
    state.addFragment[dims.type].width = dims.width
    state.addFragment[dims.type].height = dims.height
  },
  setActiveTab(state, tabName) {
    state.activeTab = tabName
  },
  setField(state, fieldData) {
    if (fieldData.subfield) {
      state[fieldData.field.name][fieldData.subfield.name] =
        fieldData.subfield.value
      return
    }
    state[fieldData.field.name] = fieldData.field.value
  },
  navigateToImage(state, item) {
    router.push({
      name: 'edit',
      params: { item: item },
      query: { id: item.id, tab: 'original' }
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
