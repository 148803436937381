const TTable = {
  tableClass: 'w-full bg-white rounded border table',
  theadClass: {
    thead: '',
    tr: 'border',
    th: 'uppercase text-xs font-bold p-3 text-gray-700'
  },
  tbodyClass: {
    tbody: '',
    tr: 'border-t',
    td: 'p-3'
  },
  tfootClass: {
    tfoot: '',
    tr: 'border-t',
    td: 'p-3'
  }
}

export default TTable
