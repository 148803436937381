<template>
  <transition name="fade">
    <div
      class="flex content-center justify-center flex-wrap bg-gray-700-trans-75 z-50 h-screen absolute top-0 left-0 w-full"
      v-if="isLoading"
    >
      <div class="w-1/5 p-2">
        <div
          class="text-gray-700 bg-gray-300 p-6 rounded shadow-2xl"
          v-if="isLoading"
        >
          <base-icon class="my-3 mx-auto spinning" width="30" height="30">
            <icon-hourglass />
          </base-icon>
          <p class="font-black text-xl text-center">{{ loadingMessage }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconHourglass from '@components/icons/icon-hourglass'
import { mapState } from 'vuex'
export default {
  name: 'BaseLoadingOverlay',
  components: {
    IconHourglass
  },
  computed: {
    ...mapState(['isLoading', 'loadingMessage'])
  }
}
</script>

<style scoped>
.bg-gray-700-trans-75 {
  background-color: rgba(0, 0, 0, 0.75);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
